<template>
    <div class="content section_detail_holder sections_list_holder" v-if="Object.keys(program_data).length > 0">

        <div class="sections_upper_holder">
            <div>
                <!-- Evalvation title -->
                <div class="evalvation_title" id="evalvation_title">
                    Samoevalvacijski obrazec: {{program_data_name}} ({{program_data_code}}) za leto {{program_data_year}}
                </div>

                <!-- Already submitted warning -->
                <div v-if="program_data.finished == 1 || program_data.submitted == 1 || program_data.disabled == 1 || program_data.ly_extended_finished == 1" class="questionnaire_warning warning">
                    <p v-if="program_data.finished == 1 && program_data.submitted == 1">Izpolnjevanje obrazca za ta program je zaključeno, obrazec programa oddan</p>
                    <p v-else-if="program_data.finished == 1">Izpolnjevanje obrazca za ta program je zaključeno</p>
                    <p v-else-if="program_data.submitted == 1">Obrazec programa je oddan</p>
                    <p v-else-if="program_data.disabled == 1">Izpolnjevanje obrazca za ta program je onemogočeno</p>
                    <p v-else-if="program_data.ly_extended_finished == 1">Obrazec, izpolnjen v prejšnjem študijskem letu, velja tudi za to študisjko leto.</p>
                </div>

                <!-- Not active year warning -->
                <div v-if="!isActiveYear" class="questionnaire_warning warning">
                    <p>Izbrali ste študijsko leto, ki trenutno ni aktivno. Pri urejanju podatkov bodite še posebej previdni!</p>
                </div>
                
                <div class="evalvation_title_second">
                    {{active_page_name}}
                </div>
            </div>

            <div class="evalvation_right_actions">
                <div class="submit_sections_buttons" v-if="(getUserType == 1 || getUserType == 2) && program_data.finished == '1' && program_data.submitted == '0' && program_data.disabled == '0'">
                    <!-- Button for repealing submitting sections data -->
                    <div class="button_holder submit_sections_data">
                        <div v-if="true" class="button large" @click="repeal_finished()">
                            Omogoči urejanje
                        </div>
                    </div>
                </div>
            
                <!-- Exports icons - temp disabled for doctorate programs -->
                <div class="file_exports in_column" v-if="q_type!='4' && program_data.ly_extended_finished == 0">
                    <!--<div class="as_link icon right_margin" title="Izvoz PDF" @click="/*deleteAttachmentConfirm(attachment.id)*/">
                        <font-awesome-icon :icon="['far', 'file-pdf']" />
                    </div>-->
                    <div class="as_link icon" title="Navaden izvoz (z navodili, s slikami)" @click="exportQuestionnaire({questionnaire_id: questionnaire_details.id, format: 'doc',
                        program_id: get_one_program_id, additional_programs:Array.from(programs_id).slice(1), export_mode: 'full'})">
                        <font-awesome-icon :icon="['far', 'file-word']" /><span class="export_text">Navaden izvoz</span>
                    </div>

                    <div 
                        class="as_link icon" 
                        title="Skrčen izvoz (brez navodil, brez slik)" 
                        @click="exportQuestionnaire({
                            questionnaire_id: questionnaire_details.id, 
                            format: 'doc',
                            program_id: get_one_program_id, 
                            additional_programs:Array.from(programs_id).slice(1), 
                            export_mode: 'short'
                        })
                    ">
                        <font-awesome-icon :icon="['far', 'file-word']" /><span class="export_text">Skrčen izvoz</span>
                    </div>

                    <div 
                        v-if="getUserType > 0 && !is_internal_questionnaire && active_page_order_number != 1"
                        class="as_link icon" 
                        title="Izvoz rubrike vseh programov" 
                        @click="exportQuestionnaireLevelAllPrograms({
                            questionnaire_id: questionnaire_details.id, 
                            format: 'doc',
                            level_id: active_page_id,
                            faculty_id: program_data.faculty_id
                        })
                    ">
                        <font-awesome-icon :icon="['far', 'file-word']" /><span class="export_text">Izvoz rubrike vseh programov</span>
                    </div>

                    <div 
                        v-if="getUserType > 0 && !is_internal_questionnaire && active_page_order_number != 1"
                        class="as_link icon" 
                        title="Izvoz rubrike vseh programov" 
                        @click="exportQuestionnaireLevelAllPrograms({
                            questionnaire_id: questionnaire_details.id, 
                            format: 'xls',
                            level_id: active_page_id,
                            faculty_id: program_data.faculty_id
                        })
                    ">
                        <font-awesome-icon :icon="['far', 'file-excel']" /><span class="export_text">Izvoz rubrike vseh programov</span>
                    </div>
                </div>
            </div>
        </div>
        <br>

        <div class="sections_intro" v-if="get_questionnaire_intro && get_questionnaire_intro !== ''">
            <div class="sections_intro_text" v-html="get_questionnaire_intro"></div>
        </div>


        <!-- Izpis roka za oddajo (datuma) znotraj področja, v kolikor ga je administrator nastavil
        <p class="deadline" :class="getDeadlineClass(section.deadline)" v-if="section.deadline !== '0000-00-00'"> Rok za oddajo: {{getDeadlineDate(section.deadline)}}</p> -->

        <div class="section_detail_content">

            <!-- Left menu - pages -->
            <ul-questionnaire-pages :build_mode="false" :q_type="q_type"></ul-questionnaire-pages>

            <div class="right">
                <!-- Questionnaire details -->
                <ul-questionnaire-detail v-if="active_page != 'RU'" :build_mode="false" :q_type="q_type"></ul-questionnaire-detail>

                <!--<br v-if="active_page != 'RU'"> -->

                <!-- Questionnaire questions -->
                <ul-questionnaire-questions :build_mode="false" :q_type="q_type"></ul-questionnaire-questions>
            </div>
        </div>
    </div>

    <div class="content section_detail_holder" v-else>
        <br>
        <div class="section_detail_content">
            <div class="already_submitted_warning warning">
                <text-no-data></text-no-data>
            </div>
        </div>
    </div>
</template>


<script>
    import { mapActions } from 'vuex';
    import QuestionnaireBuildDetail from './EvalvationQuestionnaireBuildDetail.vue';
    import QuestionnairePageQuestions from './EvalvationQuestionnairePageQuestions.vue';
    import ProgramPages from './EvalvationProgramPages.vue';
    
    //Text warning template for program not exist
    var programNoData = {
        template: '<p>Ni podatkov za izbran program. Preverite, ali je izbrano študijsko leto združljivo s programom, do katerega želite dostopati.</p>'
    };

	export default {
		name: 'EvalvationProgramDetail',
		components: {
            'ul-questionnaire-detail': QuestionnaireBuildDetail,
            'ul-questionnaire-questions': QuestionnairePageQuestions,
            'ul-questionnaire-pages': ProgramPages,
            'text-no-data': programNoData,
        },
        data: function() {
			return {
                programs: this.$route.params.programs,
                q_type: this.$route.params.q_type,
			}
        },
        computed: {
            getYear(){
				return this.$store.getters.year;
			},

            // Get loged user type
			getUserType(){
				return this.$store.getters.getUser.type;
            },

            // Check if we are in active year
			isActiveYear(){
				return this.$store.getters.is_active_year;
            },

            active_page_id(){
                return this.$store.getters.active_q_page_id;
            },

            // Selected program year
			program_data_year(){
                //if user is admin, pull program data from admins program object
                if(this.programs_id.length > 0){
                    return this.$store.getters.evalvation_program_detail.year;
                }
            },

            // Selected program name
			program_data_name(){
                //if user is admin, pull program data from admins program object
                if(this.programs_id.length > 0){
                    if(this.program_detail.ul_program_id){
                        let name = this.program_detail.name + ', ';
                        if(this.program_detail.additional_programs && Object.keys(this.program_detail.additional_programs).length > 0){
                            for(let id in this.program_detail.additional_programs){
                                if(this.program_detail.additional_programs[id])
                                    name += this.program_detail.additional_programs[id].name + ', ';
                                else
                                    return '';
                            }
                        }
                        name = name.slice(0, -2)
                        return name;
                    }
                }
            },

            // Selected program code
			program_data_code(){
                //if user is admin, pull program data from admins program object
                if(this.programs_id.length > 0){
                    if(this.program_detail.ul_program_id){
                        let name = this.program_detail.ul_program_id + ', ';
                        if(this.program_detail.additional_programs && Object.keys(this.program_detail.additional_programs).length > 0){
                            for(let id in this.program_detail.additional_programs){
                                if(this.program_detail.additional_programs[id])
                                    name += this.program_detail.additional_programs[id].ul_program_id + ', ';
                                else
                                    return '';
                            }
                        }
                        name = name.slice(0, -2)
                        return name;
                    }
                }
            },

            program_detail(){
                return this.$store.getters.evalvation_program_detail;
            },

            program_data(){
                if(this.programs_id.length == 0 && !Number.isInteger(this.programs_id))
                    return {};
                else if(this.getUserType < 2){
                    for(let program_id of this.programs_id){
                        if(!this.$store.getters.evalvation_programs_user[program_id])
                            return {};
                    }
                }
                return this.$store.getters.evalvation_program_detail;
            },

            // Get details of questionnaire
			questionnaire_details(){
                if(this.$store.getters.evalvation_questionnaire_detail.id){
                    return this.$store.getters.evalvation_questionnaire_detail;
                }
                return null;
            },

            // Is there any page
			is_page(){
                if(!this.$store.getters.active_q_page_id)
                    return false;
                return true;
            },

            // Get name of active page
			active_page_name(){
                if(!this.is_page){
                    return "Obrazec za to leto še ne obstaja";
                }
                else if(this.active_page == "RU")
                    return "Pregled realizacije ukrepov in predlogi izboljšav";
                else if(this.active_page && (this.$store.getters.evalvation_questionnaire_detail.pages || this.$store.getters.evalvation_internal_questionnaire_detail.pages)){
                    if(this.$store.getters.evalvation_questionnaire_detail.pages[this.$store.getters.active_q_page_id])
                        return this.$store.getters.evalvation_questionnaire_detail.pages[this.$store.getters.active_q_page_id].name;
                    else
                        return this.$store.getters.evalvation_internal_questionnaire_detail.pages[this.$store.getters.active_q_page_id].name;
                }
            },

            active_page_order_number(){
                if(this.$store.getters.evalvation_questionnaire_detail.pages && this.$store.getters.evalvation_questionnaire_detail.pages[this.$store.getters.active_q_page_id])
                    return this.$store.getters.evalvation_questionnaire_detail.pages[this.$store.getters.active_q_page_id].order_number;
                return 0;
            },

            is_internal_questionnaire(){
                return (this.active_page_id && this.$store.getters.evalvation_internal_questionnaire_detail.pages && this.$store.getters.evalvation_internal_questionnaire_detail.pages[this.active_page_id]);
            },

            // Get active page
			active_page(){
                return this.$store.getters.active_q_page_id;
            },

            // Get questionnaire intro
			get_questionnaire_intro(){
                return this.$store.getters.evalvation_questionnaire_detail.intro;
            },

            programs_id(){
                return this.programs.toString().split(",");
            },

            get_one_program_id(){
                if(Array.isArray(this.programs_id))
                    return this.programs_id[0];
                return this.programs_id;
            },
		}, 
        methods: { 		
            ...mapActions([
                'showModal',
                'getProgramDetail',
                'getProgramsListForUser',
                'getQuestionnaireDetails',
                'getProgramsListForFaculty',
                'exportQuestionnaire',
                'exportQuestionnaireLevelAllPrograms'
            ]),

            // Click on repeal finished of program
            repeal_finished(){
                this.showModal({component: 'evalvation/RepealFinishedProgram', data:{program_id: this.get_one_program_id}});
            },
        },
        created(){
            this.getQuestionnaireDetails({type: this.q_type});
            this.getProgramDetail({program_id:this.get_one_program_id, additional_programs:Array.from(this.programs_id).slice(1)});

            if(this.getUserType == 1)
                this.getProgramsListForFaculty();
            else if(this.getUserType == 0)
                this.getProgramsListForUser();
		},
	}
</script>


<style lang="scss" scoped>
	@import "../../scss/style.scss";
</style>

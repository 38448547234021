<template>
    <div class="evalvation_questions_holder">

        <div v-if="active_page_id !== 'RU' && !disable_input && skip_enabled && (q_type == 1 || q_type == 4)"
            class="skip_page_button">
            <div class="skip_button button gray"
                @click="fillAllQuestionsPage('Na ŠP nimamo organiziranega praktičnega usposabljanja študentov kot samostojne učne enote')">
                Na ŠP nimamo organiziranega praktičnega usposabljanja študentov kot samostojne učne enote
            </div>
        </div>

        <div class="questions_list" :class="full_width">
            <div v-if="active_page_id == 'RU' && table_data.table1">
                <ul-questionnaire-tables :build_mode="build_mode"></ul-questionnaire-tables>
            </div>

            <div v-else>
                <div class="question_add_holder"
                    v-if="build_mode && page_data[active_page_id] && (((q_type != 1 && q_type != 4) && page_data[active_page_id].order_number != 1) ||
                    ((getUserType == 2 && active_page_id !== 'RU' && page_data[active_page_id].order_number != 1 && page_data[active_page_id].order_number != 2) || is_internal_questionnaire))">
                    <div class="as_link icon question_add" title="Dodaj vprašanje"
                        @click="showModal({ component: 'evalvation/CreateQuestionnaireQuestion', data: { q_type: q_type, order_number: 1 } })">
                        <font-awesome-icon :icon="['far', 'plus-square']" /> Dodaj vprašanje
                    </div>
                </div>

                <ul-questionnaire-questions :build_mode="build_mode" :q_type="q_type"></ul-questionnaire-questions>
            </div>
        </div>

        <div class="questionnaire_navigation" v-if="!build_mode">
            <div class="page_navigator button gray" :class="check_first_page" title="Prejšnja stran"
                @click="prevPage()">
                <font-awesome-icon :icon="['fas', 'arrow-left']" /> Prejšnja stran
            </div>
            <div v-show="active_page_id !== 'RU'" class="page_navigator button" :class="check_last_page"
                title="Naslednja stran" @click="nextPage()">
                Naslednja stran
                <font-awesome-icon :icon="['fas', 'arrow-right']" />
            </div>

            <div v-if="getUserType != 3 && (((q_type == 1 && active_page_id == 'RU') || (q_type != 1 && check_last_page == 'hidden')) && (programs_id.length > 1 || ((q_type == 1 || q_type == 4)
            && (program_data.finished == 0 && program_data.submitted == 0 && program_data.disabled == 0 && program_data.ly_extended_finished == 0))))"
                class="page_navigator button" title="Zaključi" @click="endFillOut()">
                Zaključi
                <font-awesome-icon :icon="['fas', 'check']" />
            </div>

            <div v-if="this.q_type.toString().startsWith('2_') && getUserType != 3 && check_last_page == 'hidden' && program_data.finished == 0"
                class="page_navigator button" title="Zaključi" @click="endFillOutDepartment()">
                Zaključi
                <font-awesome-icon :icon="['fas', 'check']" />
            </div>
        </div>

    </div>
</template>


<script>
import Vue from 'vue';
import { mapActions } from 'vuex';
import QuestionnaireTables from './EvalvationQuestionnaireTables.vue';
import QuestionnaireQuestions from './EvalvationQuestionnaireQuestions.vue';

export default {
    name: 'EvalvationQuestionnairePageQuestions',
    components: {
        'ul-questionnaire-tables': QuestionnaireTables,
        'ul-questionnaire-questions': QuestionnaireQuestions,
    },
    data: function () {
        return {
        }
    },
    props: {
        build_mode: {
            type: Boolean,
            required: true
        },
        q_type: {
            type: [String, Number],
            required: true
        }
    },
    computed: {
        // Selected questionnaire object for program
        page_data() {
            if (this.active_page_id && this.$store.getters.evalvation_questionnaire_detail.pages &&
                (this.active_page_id == 'RU' || this.$store.getters.evalvation_questionnaire_detail.pages[this.active_page_id]))
                return this.$store.getters.evalvation_questionnaire_detail.pages;
            else if (this.active_page_id && this.$store.getters.evalvation_internal_questionnaire_detail.pages && this.$store.getters.evalvation_internal_questionnaire_detail.pages[this.active_page_id])
                return this.$store.getters.evalvation_internal_questionnaire_detail.pages;
            else
                return {};
        },

        is_internal_questionnaire() {
            return this.active_page_id && this.$store.getters.evalvation_internal_questionnaire_detail.pages && this.$store.getters.evalvation_internal_questionnaire_detail.pages[this.active_page_id];
        },

        // Data of table for program
        table_data() {
            if (this.$store.getters.table_details.table1)
                return this.$store.getters.table_details;
            else
                return {};
        },

        //table 3 data 
        table3_data: function () {
            if (this.table_data.table3 && this.table_data.table3.data.data) {
                let table3_data = Object.values(this.table_data.table3.data.data);

                return table3_data.filter((row) => {
                    return (row.column2_value !== 'Za to področje ni predvidenih ukrepov.'
                        && row.column2_value !== '"Za to področje ni predvidenih ukrepov."'
                        && row.column2_value !== '/')
                });
            }
            else
                return {};
        },

        //get IDs of all programs for this questionnaire
        programs_id() {
            if (this.q_type == 1 || this.q_type == 4)
                return this.$route.params.programs.toString().split(",");
            else
                return this.$route.params.department;
        },

        // Get program data
        program_data() {
            if (this.build_mode)
                return {};

            //departments start with 2_
            if (this.q_type.toString().startsWith('2_'))
                return this.$store.getters.evalvation_department_detail;
            //programs questionnaire is 1, internal questionnaire starts with 3_, doctorate is 4
            else
                return this.$store.getters.evalvation_program_detail;

        },

        //is this page skippable
        skip_enabled() {
            return (this.page_data[this.active_page_id] && this.page_data[this.active_page_id].skip_enabled == 1);
        },

        // Create and return object of pages order-id pairs
        page_order_data() {
            let page_data = this.page_data;
            let page_order_data = {};

            for (let page_id in page_data) {
                page_order_data[page_data[page_id].order_number] = page_id;
            }

            return page_order_data;
        },
        //make tables full width
        full_width() {
            if (this.active_page_id == 'RU')
                return 'full_width';
            else
                return '';
        },
        //id of active page
        active_page_id() {
            return this.$store.getters.active_q_page_id;
        },
        //get answers for this questionnaire
        questionnaire_data() {
            if (this.build_mode)
                return {};
            else {
                return this.$store.getters.questionnaire_data;
            }
        },
        // Get loged user type
        getUserType() {
            return this.$store.getters.getUser.type;
        },

        //get order number of current page
        getOrderNumber() {
            if (this.page_data[this.active_page_id])
                return this.page_data[this.active_page_id].order_number;
            return 1;
        },

        //Get breaks
        program_breaks() {
            if (this.q_type !== 1)
                return {};
            if (this.getUserType == 2 || this.getUserType == 3)
                return this.$store.getters.evalvation_program_detail.breaks;
            else
                return this.$store.getters.evalvation_programs_user[this.programs_id].breaks;
        },

        //check if active page is last in order
        check_last_page() {
            if (this.q_type != 1 || this.is_internal_questionnaire) {
                let last_num = 0;
                for (let key in this.page_order_data)
                    last_num = key;

                if (last_num == this.getOrderNumber || last_num == 0)
                    return 'hidden';
            }
            else {
                if (this.active_page_id == 'RU')
                    return 'hidden';

                if (this.active_page_id == 'SP')
                    return '';

                if (!this.page_data[this.active_page_id])
                    return 'hidden';
            }

            return '';
        },

        //check if active page is first in order
        check_first_page() {
            /*if(this.active_page_id == 'SP')
                return 'hidden';

            if(this.active_page_id == 'RU')
                return '';

            if(!this.page_data[this.active_page_id])
                return 'hidden';
                
            return '';*/

            if (this.active_page_id == 'RU')
                return '';

            if (!this.page_data[this.active_page_id])
                return 'hidden';

            if (1 == this.getOrderNumber)
                return 'hidden';
            return '';
        },

        get_one_program_id() {
            if (Array.isArray(this.programs_id))
                return this.programs_id[0];
            return this.programs_id;
        },

        disable_input() {
            return this.getUserType == 3 || (!this.is_internal_questionnaire && (this.q_type == 1 || this.q_type == 4) &&
                (this.build_mode || (this.getUserType < 2 && (this.program_data.finished == 1 || this.program_data.submitted == 1 || this.program_data.disabled == 1 || this.program_data.ly_extended_finished == 1))));
        },

        //get answers for this questionnaire
        questionnaire_multiple_data() {
            return this.$store.getters.questionnaire_multiple_data;
        },
    },
    methods: {
        ...mapActions([
            'showModal',
            'getQuestionnaireData',
            'getProgramDashboard',
            'changeActivePageQ',
            'getDepartmentQuestionnaireData',
            'setDepartmentFinished',
            'saveQuestionData',
            'addQuestionTableRow'
        ]),

        //fill out all questions on active page (page skipping)
        fillAllQuestionsPage(text) {
            this.showModal({
                component: 'evalvation/AutoFillQuestionsConfirm', data: {
                    todo: () => {
                        for (let question in this.page_data[this.active_page_id].questions) {
                            let qid = this.page_data[this.active_page_id].questions[question].id;
                            let question_type = this.page_data[this.active_page_id].questions[question].type;
                            if (question_type != 2 && this.q_type == 1 && (!this.questionnaire_data[qid] || !this.questionnaire_data[qid].answer)) {
                                Vue.set(this.questionnaire_data, qid, { answer: text });
                                this.saveAnswer(qid, text);
                            }
                            else if (question_type != 2) {
                                this.questionnaire_data[qid].answer = text;
                                this.saveAnswer(qid, text);
                            }
                            else if (question_type == 2 && this.page_data[this.active_page_id].questions[question].improvements == 1) {
                                //add row to table
                                this.addQuestionTableRow({dep_or_prog_id:this.programs_id, question_id:qid, 
                                    q_order_num: this.page_data[this.active_page_id].questions[question].order_number, 
                                    questionnaire_type:this.q_type, additional_programs: Array.from(this.programs_id).slice(1),
                                    value: 'Za to področje ni predvidenih ukrepov.', clear_table:true});
                            }
                            else if (question_type == 2) {
                                //add row to table
                                this.addQuestionTableRow({dep_or_prog_id:this.programs_id, question_id:qid, 
                                    q_order_num: this.page_data[this.active_page_id].questions[question].order_number, 
                                    questionnaire_type:this.q_type, additional_programs: Array.from(this.programs_id).slice(1),
                                    value: text, clear_table:true});
                            }
                        }
                    }
                }
            });
        },

        //save answer
        saveAnswer(question_id, value) {
            let additional_programs = Array.from(this.programs_id).slice(1);
            this.saveQuestionData({ program_id: this.programs_id[0], question_id: question_id, value: value, additional_programs: additional_programs });
        },

        nextPage() {
            let next_order_number = 0;
            if (this.active_page_id == 'SP')
                next_order_number = 1;
            else
                next_order_number = parseInt(this.getOrderNumber) + 1;

            if (this.page_order_data[next_order_number])
                this.changeActivePage(this.page_order_data[next_order_number]);
            else
                this.changeActivePage('RU');

            //timeout needed because new browsers are so fast that sometimes scrollTo() action fires before browsers default scroll to html anchor event
            //scroll to top
            //setTimeout(function() {window.scrollTo({top: 0, behavior: 'smooth'});},50);
            //scroll to title
            let element = document.getElementById("evalvation_title");
            setTimeout(function () { element.scrollIntoView({ behavior: "smooth" }) }, 50);
            //instant jump to title - works every time but its not smooth
            //location.href = "#evalvation_title";
        },

        prevPage() {
            if (this.active_page_id == 'RU') {
                this.changeActivePage(Object.keys(this.page_data).pop());
            }
            else {
                let prev_order_number = parseInt(this.getOrderNumber) - 1;
                if (prev_order_number == 0)
                    this.changeActivePage('SP');
                else
                    this.changeActivePage(this.page_order_data[prev_order_number]);
            }

            //timeout needed because new browsers are so fast that sometimes scrollTo() action fires before browsers default scroll to html anchor event
            //scroll to top
            //setTimeout(function() {window.scrollTo({top: 0, behavior: 'smooth'});},50);
            //scroll to title
            let element = document.getElementById("evalvation_title");
            setTimeout(function () { element.scrollIntoView({ behavior: "smooth" }) }, 50);
            //instant jump to title - works every time but its not smooth
            //location.href = "#evalvation_title";
        },

        check_break(q_id) {
            if (!this.questionnaire_data[q_id].breaks)
                this.questionnaire_data[q_id].breaks = {};
            return true;
        },

        changeActivePage(id) {
            this.changeActivePageQ(id);
        },

        endFillOutDepartment(){
            this.setDepartmentFinished({department_id: this.programs_id});
        },

        //end fiull out clicked
        endFillOut() {
            let first_page = Object.keys(this.page_data)[0];
            //let last_page = Object.keys(this.page_data)[Object.keys(this.page_data).length - 1];

            if (Object.keys(this.page_data).length == 0) {
                this.showModal({ component: 'ModalWarning', data: { title: "Obrazec ni izpolnjen", description: "Niste izpolnili vseh polj v obrazcu. Prosimo, izpolnite vsa polja, preden zaključite z izpolnjevanjem." } });
                return false;
            }

            //go trough every page
            for (let page_id in this.page_data) {
                //let last_question = Object.keys(this.page_data[page_id].questions)[Object.keys(this.page_data[page_id].questions).length - 1];

                let hidden_questions = 0;

                //when we are in first page, there can be question for multiple programs (one question & multiple inputs)
                //data for that kind of questions is stored in separate object
                if (page_id == first_page && this.programs_id.length > 1) {
                    for (let question_on in this.questionnaire_multiple_data) {
                        for (let program_on in this.questionnaire_multiple_data[question_on]) {
                            if (!this.questionnaire_multiple_data[question_on][program_on] || this.questionnaire_multiple_data[question_on][program_on] == "") {
                                this.showModal({ component: 'ModalWarning', data: { title: "Obrazec ni izpolnjen", description: "Niste izpolnili vseh polj v obrazcu (stran 1). Prosimo, izpolnite vsa polja, preden zaključite z izpolnjevanjem." } });
                                return false;
                            }
                        }
                    }
                }

                else {
                    for (let question_on in this.page_data[page_id].questions) {
                        let question_id = this.page_data[page_id].questions[question_on].id;
                        let question_type = this.page_data[page_id].questions[question_on].type;
                        let question_stopnja = this.page_data[page_id].questions[question_on].stopnja;
                        let question_vrsta = this.page_data[page_id].questions[question_on].vrsta;

                        let programs_skippable = 1;
                        let skippable = false;

                        //do it only for visible questions
                        if(this.questionVisible(question_stopnja, question_vrsta)){

                            //if single program in first year (no need to program to be skippable after 2020/2021)
                            if (this.program_data.year == '2020/2021') {
                                if (this.programs_id.length == 1) {
                                    programs_skippable = this.program_data.skippable;
                                }

                                //if multiple programs
                                else {
                                    //if main program is skippable on 0, end here
                                    if (this.program_data.skippable == 0) {
                                        programs_skippable = 0;
                                    }
                                    //go trough additional programs
                                    else {
                                        for (let pid in this.program_data.additional_programs) {
                                            if (this.program_data.additional_programs[pid].skippable == 0) {
                                                programs_skippable = 0;
                                                break;
                                            }
                                        }
                                    }
                                }

                                //both question and programs must be skippable
                                skippable = this.page_data[page_id].questions[question_on].skippable == 1 && programs_skippable == 1;

                                //TODO - pri skupnem izpolnjevanju se zakomplicira pri breakih
                                /*if(this.program_breaks && this.page_data[page_id].questions[question_on].break == 1){
                                    this.check_break(question_id)
                                    for(let breakk in this.program_breaks){
                                        if(!this.questionnaire_data[question_id].breaks[breakk] || this.questionnaire_data[question_id].breaks[breakk] == ""){
                                            this.showModal({component: 'ModalWarning', data:{title:"Obrazec ni izpolnjen", description:"Niste izpolnili vseh polj v obrazcu. Prosimo, izpolnite vsa polja, preden zaključite z izpolnjevanjem."}});
                                            return false;
                                        }
                                    }
                                }
                                else{*/
                                if (!skippable && (!this.questionnaire_data[question_id] || (!this.questionnaire_data[question_id].answer || this.questionnaire_data[question_id].answer == "")
                                    && (!this.questionnaire_data[question_id].table_rows || Object.keys(this.questionnaire_data[question_id].table_rows).length == 0) /*&& 
                                            (page_id !== first_page && page_id !== first_page && question_on !== last_question)*/)) {
                                    this.showNotFilledWarning(this.page_data[page_id].name, (question_on-hidden_questions));
                                    return false;
                                }
                                //check all cells in table
                                else if (this.questionnaire_data[question_id] && this.questionnaire_data[question_id].table_rows) {
                                    for (let row_id in this.questionnaire_data[question_id].table_rows) {
                                        for (let col_id in this.questionnaire_data[question_id].table_rows[row_id].table_columns) {
                                            if (this.questionnaire_data[question_id].table_rows[row_id].table_columns[col_id] == "") {
                                                this.showNotFilledWarning(this.page_data[page_id].name, (question_on-hidden_questions));
                                                return false;
                                            }
                                        }
                                    }
                                }
                                //}
                            }
                            //after 2020/2021 only question has to be skippable
                            else {
                                skippable = this.page_data[page_id].questions[question_on].skippable == 1;

                                //if question is not skippable
                                if (!skippable && (!this.questionnaire_data[question_id] || (!this.questionnaire_data[question_id].answer || this.questionnaire_data[question_id].answer == "")
                                    && (!this.questionnaire_data[question_id].table_rows || Object.keys(this.questionnaire_data[question_id].table_rows).length == 0) /*&& 
                                    (page_id !== first_page && page_id !== first_page && question_on !== last_question)*/)) {

                                    this.showNotFilledWarning(this.page_data[page_id].name, (question_on-hidden_questions));
                                    return false;
                                }
                                //skippable questions
                                else if (skippable) {
                                    //text question
                                    if (question_type == 1 && (!this.questionnaire_data[question_id] ||
                                        (!this.questionnaire_data[question_id].answer_last_year && !this.questionnaire_data[question_id].answer))) {
                                        this.showNotFilledWarning(this.page_data[page_id].name, (question_on-hidden_questions));
                                        return false;
                                    }
                                    //table question
                                    else if (question_type == 2 && (!this.questionnaire_data[question_id] ||
                                        (!this.questionnaire_data[question_id].table_rows_last_year
                                            || Object.keys(this.questionnaire_data[question_id].table_rows_last_year).length == 0) &&
                                        (!this.questionnaire_data[question_id].table_rows
                                            || Object.keys(this.questionnaire_data[question_id].table_rows).length == 0))) {
                                        this.showNotFilledWarning(this.page_data[page_id].name, (question_on-hidden_questions));
                                        return false;
                                    }
                                }

                                //table question check all cells
                                if (question_type == 2 && this.questionnaire_data[question_id] && this.questionnaire_data[question_id].table_rows) {
                                    for (let row_id in this.questionnaire_data[question_id].table_rows) {
                                        for (let col_id in this.questionnaire_data[question_id].table_rows[row_id].table_columns) {
                                            if (this.questionnaire_data[question_id].table_rows[row_id].table_columns[col_id] == "") {
                                                this.showNotFilledWarning(this.page_data[page_id].name, (question_on-hidden_questions));
                                                return false;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        //question is not visible
                        else{
                            hidden_questions++;
                        }
                    }
                }
            }

            //go trough first static table
            if ((this.q_type == 1 || this.q_type == 4) && this.table_data.table1.data.data) {
                for (let table1_row of Object.values(this.table_data.table1.data.data)) {
                    if ((table1_row.reference_row_id > 0 && table1_row.column2_value !== 'Za to področje ni predvidenih ukrepov.'
                        && table1_row.column2_value !== '"Za to področje ni predvidenih ukrepov."'
                        && table1_row.column2_value !== '/' && table1_row.column2_value !== '' && table1_row.column3_value == '')
                        || (table1_row.reference_row_id == 0 && (table1_row.column2_value == '' || table1_row.column3_value == ''))) {
                        this.showNotFilledWarningLastPage();
                        return false;
                    }
                }
            }
            /* else {
                this.showNotFilledWarningLastPage();
                return false;
            } */

            if ((this.q_type == 1 || this.q_type == 4) && Object.values(this.table3_data).length > 0) {
                //go trough last static table
                for (let table3_row of this.table3_data) {
                    if (((table3_row.reference_row_id > 0 || table3_row.reference_question_id > 0) &&
                        (table3_row.column2_value == 'Za to področje ni predvidenih ukrepov.' ||
                            table3_row.column2_value == '"Za to področje ni predvidenih ukrepov."'
                            || table3_row.column2_value == '/' || table3_row.column2_value == ''|| table3_row.column6_value == ''))
                        || ((table3_row.reference_row_id == 0 && table3_row.reference_question_id == 0)
                            && (table3_row.column6_value == '' || table3_row.column2_value == ''))) {
                        this.showNotFilledWarningLastPage();
                        return false;
                    }
                }
            }
            /*else {
                this.showNotFilledWarningLastPage();
                return false;
            } */

            //show modal for sucessfully filled questionnaire
            if (this.q_type == 1 || this.q_type == 4)
                this.showModal({ component: 'evalvation/FinishFill', data: { type: 1, program_id: this.get_one_program_id, additional_programs: Array.from(this.programs_id).slice(1) } });
            else
                this.showModal({ component: 'evalvation/FillFinished', data: { type: 2 } });
        },

        showNotFilledWarning(page, question) {
            //get page initials (page mark)
            page = page.substring(0, page.indexOf(" ")).replace('.', '');
            let missing_text = '';

            if (page == 1)
                missing_text = "stran 1, podatki o skrbniku/ci";
            else
                missing_text = "stran " + page + ", vprašanje " + question;


            this.showModal({
                component: 'ModalWarning', data: {
                    title: "Obrazec ni izpolnjen", description:
                        "Niste izpolnili vseh polj v obrazcu (" + missing_text + "). Prosimo, izpolnite vsa polja, preden zaključite z izpolnjevanjem."
                }
            });
        },

        showNotFilledWarningLastPage() {
            this.showModal({
                component: 'ModalWarning', data: {
                    title: "Obrazec ni izpolnjen", description:
                        'Niste izpolnili vseh polj v obrazcu (stran "Pregled realizacije ukrepov in predlogi izboljšav"). Prosimo, izpolnite vsa polja, preden zaključite z izpolnjevanjem.'
                }
            });
        },

        questionVisible(stopnja, vrsta){
            const progst = this.program_data.stopnja;
            const progvr = this.program_data.vrsta;

            if(this.build_mode || (stopnja == 0 && vrsta == 'all')) return true;
            if(stopnja == 1 && (progst=='prva stopnja' || progst=='druga stopnja' || progst=='izpopolnjevanje' || progst=='504')) return true;
            if(stopnja == 3 && progst=='tretja stopnja') return true;
            if(vrsta == progvr) return true;

            /* if(!this.rendered_questions.includes(question_id)){
                this.hidden_questions_num++;
                this.rendered_questions.push(question_id);
            } */

            return false;
        },
    },

    watch: {
        // Resize all textareas

        //couses jumping everytime an answer is saved
        /*questionnaire_data(){
            this.$nextTick(()=>{
                [...document.querySelectorAll('textarea')].forEach(ta => {
                    ta.dispatchEvent(new Event("onload"));
                });
            });
        }, */
        active_page_id() {
            this.$nextTick(() => {
                [...document.querySelectorAll('textarea')].forEach(ta => {
                    ta.dispatchEvent(new Event("onload"));
                });
            });
        }
    },

    created() {
        if (!this.build_mode) {
            if (this.q_type == 1 || this.q_type == 4){
                this.getQuestionnaireData(this.get_one_program_id);
                this.getProgramDashboard(this.get_one_program_id);
            }
            else
                this.getDepartmentQuestionnaireData(this.programs_id);
        }
    },
}
</script>


<style lang="scss" scoped>
@import "../../scss/style.scss";

/* Transicije */
.fade-enter-active {
    transition: opacity 0.3s;
}

.fade-leave-active {
    transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>

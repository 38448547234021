<template>
    <div>
        <div class="evalvation_question_table_holder">     
            <div class="evalvation_question_table">
                <div class="row header">
                    <div class="column column_header small" v-if="year !== '2020/2021' && year !== '2019/2020'"> 
                        <div class="question_title_row">
                            <span v-if="build_mode && getUserType == 2" class="as_link icon" title="Uredi stolpec">
                                <font-awesome-icon :icon="['fas', 'pencil-alt']" @click="showModal({component: 'evalvation/QuestionEditColumnFixTable', 
                                    data:{title:table_data.table1.structure.data.column1_title, description:table_data.table1.structure.data.column1_description, column_num:1, table_num:1}})" />
                            </span>

                            <span class="title">
                                {{ table_data.table1.structure.data.column1_title }}
                            </span>
                        </div>
                        <span class="description" v-if="table_data.table1.structure.data.column1_description != ''">
                            {{ table_data.table1.structure.data.column1_description }}
                        </span>
                    </div>
                    <div class="column column_header"> 
                        <div class="question_title_row">
                            <span v-if="build_mode && getUserType == 2" class="as_link icon" title="Uredi stolpec">
                                <font-awesome-icon :icon="['fas', 'pencil-alt']" @click="showModal({component: 'evalvation/QuestionEditColumnFixTable', 
                                    data:{title:table_data.table1.structure.data.column2_title, description:table_data.table1.structure.data.column2_description, column_num:2, table_num:1}})" />
                            </span>

                            <span class="title">
                                {{ table_data.table1.structure.data.column2_title }}
                            </span>
                        </div>
                        <span class="description" v-if="table_data.table1.structure.data.column2_description != ''">
                            {{ table_data.table1.structure.data.column2_description }}
                        </span>
                    </div>
                    <div class="column column_header" v-if="year !== '2020/2021' && year !== '2019/2020'"> 
                        <div class="question_title_row">
                            <span v-if="build_mode && getUserType == 2" class="as_link icon" title="Uredi stolpec">
                                <font-awesome-icon :icon="['fas', 'pencil-alt']" @click="showModal({component: 'evalvation/QuestionEditColumnFixTable', 
                                    data:{title:table_data.table1.structure.data.column3_title, description:table_data.table1.structure.data.column3_description, column_num:3, table_num:1}})" />
                            </span>

                            <span class="title">
                                {{ table_data.table1.structure.data.column3_title }}
                            </span>
                        </div>
                        <span class="description" v-if="table_data.table1.structure.data.column3_description != ''">
                            {{ table_data.table1.structure.data.column3_description }}
                        </span>
                    </div>
                    <div class="column column_header"> 
                        <div class="question_title_row">
                            <span v-if="build_mode && getUserType == 2" class="as_link icon" title="Uredi stolpec">
                                <font-awesome-icon :icon="['fas', 'pencil-alt']" @click="showModal({component: 'evalvation/QuestionEditColumnFixTable', 
                                    data:{title:table_data.table1.structure.data.column4_title, description:table_data.table1.structure.data.column4_description, column_num:4, table_num:1}})" />
                            </span>

                            <span class="title">
                                {{ table_data.table1.structure.data.column4_title }}
                            </span>
                        </div>
                        <span class="description" v-if="table_data.table1.structure.data.column3_description != ''">
                            {{ table_data.table1.structure.data.column4_description }}
                        </span>
                    </div>
                    <div class="column column_header medium" v-if="year !== '2020/2021' && year !== '2019/2020' && year !== '2021/2022'"> 
                        <div class="question_title_row">
                            <span class="title">
                                Izvorno leto ukrepa
                            </span>
                        </div>
                        <!-- <span class="description" v-if="table_data.table1.structure.data.column1_description != ''">
                            {{ table_data.table1.structure.data.column1_description }}
                        </span> -->
                    </div>
                    <div class="column tools"></div>
                </div>

                <div v-if="!build_mode && table_data.table1.data">
                    <div class="row data" v-for="row in sorted_table1_data" :key="row.id">    
                        <div class="column small" v-if="year !== '2020/2021' && year !== '2019/2020'">
                            <div>
                                <span class="page_num">{{row.column1_value}}</span>
                            </div>
                        </div>
                        <div class="column">
                            <div>
                                <textarea rows="4" :disabled="disable_input || row.reference_row_id != '0'" v-model="table_data.table1.data.data[row.id].column2_value" @change="cellChanged(row.id, 2, 1, $event)"></textarea>
                            </div>
                        </div>
                        <div class="column" v-if="year !== '2020/2021' && year !== '2019/2020'">
                            <div>
                                <select :disabled="disable_input" 
                                        v-model="table_data.table1.data.data[row.id].column3_value"
                                        @change="cellChanged(row.id, 3, 1, $event)">
                                    <option v-for="(value, index) in status_dropdown_items" :key="index">{{value}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="column">
                            <div>
                                <textarea rows="4" :disabled="disable_input" v-model="table_data.table1.data.data[row.id].column4_value" @change="cellChanged(row.id, 4, 1, $event)"></textarea>
                            </div>
                        </div>
                        <div class="column medium" v-if="year !== '2020/2021' && year !== '2019/2020' && year !== '2021/2022'">
                            <div>
                                <span class="page_num" v-html="row.original_year"></span>
                            </div>
                        </div>
                        <div class="column tools">
                            <div class="as_link icon" title="Pobriši vrstico" v-if="!disable_input && !showRemoveRow('table1', row.id)">
                                <font-awesome-icon :icon="['far', 'trash-alt']" @click="showModal({component: 'evalvation/DeleteRowTable', data:{table:1, row_id:row.id, additional_programs:Array.from(programs_id).slice(1)}})" />
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="row data" v-for="(row, key) in {1:{}, 2:{}}" :key="key">    
                    <div class="column small" v-if="year !== '2020/2021' && year !== '2019/2020'">
                        <div>
                            <div></div>
                        </div>
                    </div>
                    <div class="column">
                        <div>
                            <textarea rows="4" :disabled="build_mode"></textarea>
                        </div>
                    </div>
                    <div class="column" v-if="year !== '2020/2021' && year !== '2019/2020'">
                        <div>
                            <select :disabled="build_mode">
                            </select>
                        </div>
                    </div>
                    <div class="column">
                        <div>
                            <textarea rows="4" :disabled="build_mode"></textarea>
                        </div>
                    </div>
                    <div class="column medium" v-if="year !== '2020/2021' && year !== '2019/2020' && year !== '2021/2022'">
                        <div>
                            <div></div>
                        </div>
                    </div>
                    <div class="column tools">
                    
                    </div>
                </div>

                <div class="row add-data" v-if="!disable_input">
                    <div class="as_link icon" title="Dodaj vrstico" @click="addTableRow(1)">
                        <font-awesome-icon :icon="['far', 'plus-square']" /> Dodaj vrstico
                    </div>
                </div>
            </div>
            <br><br><br>
        </div>

        <div class="evalvation_question_table_holder">     
            <div class="evalvation_question_table">
                <div class="row header">
                    <div class="column column_header"> 
                        <div class="question_title_row">
                            <span v-if="build_mode && getUserType == 2" class="as_link icon" title="Uredi stolpec">
                                <font-awesome-icon :icon="['fas', 'pencil-alt']" @click="showModal({component: 'evalvation/QuestionEditColumnFixTable', 
                                    data:{title:table_data.table2.structure.data.column1_title, description:table_data.table2.structure.data.column1_description, column_num:1, table_num:2}})" />
                            </span>

                            <span class="title">
                                {{ table_data.table2.structure.data.column1_title }}
                            </span>
                        </div>
                        <span class="description" v-if="table_data.table2.structure.data.column1_description != ''">
                            {{ table_data.table2.structure.data.column1_description }}
                        </span>
                    </div>
                    <div class="column column_header"> 
                        <div class="question_title_row">
                            <span v-if="build_mode && getUserType == 2" class="as_link icon" title="Uredi stolpec">
                                <font-awesome-icon :icon="['fas', 'pencil-alt']" @click="showModal({component: 'evalvation/QuestionEditColumnFixTable', 
                                    data:{title:table_data.table2.structure.data.column2_title, description:table_data.table2.structure.data.column2_description, column_num:2, table_num:2}})" />
                            </span>

                            <span class="title">
                                {{ table_data.table2.structure.data.column2_title }}
                            </span>
                        </div>
                        <span class="description" v-if="table_data.table2.structure.data.column2_description != ''">
                            {{ table_data.table2.structure.data.column2_description }}
                        </span>
                    </div>
                    <div class="column tools"></div>
                </div>

                <div v-if="!build_mode && table_data.table2.data">
                    <div class="row data" v-for="(row, key) in table_data.table2.data.data" :key="key">    
                        <div class="column">
                            <div>
                                <textarea rows="4" :disabled="disable_input" v-model="table_data.table2.data.data[key].column1_value" @change="cellChanged(key, 1, 2, $event)"></textarea>
                            </div>
                        </div>
                        <div class="column">
                            <div>
                                <textarea rows="4" :disabled="disable_input" v-model="table_data.table2.data.data[key].column2_value" @change="cellChanged(key, 2, 2, $event)"></textarea>
                            </div>
                        </div>
                        <div class="column tools">
                            <div class="as_link icon" title="Pobriši vrstico" v-if="!disable_input && !showRemoveRow('table2', key)">
                                <font-awesome-icon :icon="['far', 'trash-alt']" @click="showModal({component: 'evalvation/DeleteRowTable', data:{table:2, row_id:key, additional_programs:Array.from(programs_id).slice(1)}})" />
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="row data" v-for="(row, key) in {1:{}, 2:{}}" :key="key">    
                    <div class="column">
                        <div>
                            <textarea rows="4" :disabled="build_mode"></textarea>
                        </div>
                    </div>
                    <div class="column">
                        <div>
                            <textarea rows="4" :disabled="build_mode"></textarea>
                        </div>
                    </div>
                    <div class="column tools">
                    
                    </div>
                </div>

                <div class="row add-data" v-if="!disable_input">
                    <div class="as_link icon" title="Dodaj vrstico" @click="addTableRow(2)">
                        <font-awesome-icon :icon="['far', 'plus-square']" /> Dodaj vrstico
                    </div>
                </div>
            </div>
            <br><br><br>
        </div>

        <div class="evalvation_question_table_holder">     
            <div class="evalvation_question_table">
                <div class="row header">
                    <div class="column column_header small"> 
                        <div class="question_title_row">
                            <span v-if="build_mode && getUserType == 2" class="as_link icon" title="Uredi stolpec">
                                <font-awesome-icon :icon="['fas', 'pencil-alt']" @click="showModal({component: 'evalvation/QuestionEditColumnFixTable', 
                                    data:{title:table_data.table3.structure.data.column1_title, description:table_data.table3.structure.data.column1_description, column_num:1, table_num:3}})" />
                            </span>

                            <span class="title">
                                {{ table_data.table3.structure.data.column1_title }}
                            </span>
                        </div>
                        <span class="description" v-if="table_data.table3.structure.data.column1_description != ''">
                            {{ table_data.table3.structure.data.column1_description }}
                        </span>
                    </div>
                    <div class="column column_header"> 
                        <div class="question_title_row">
                            <span v-if="build_mode && getUserType == 2" class="as_link icon" title="Uredi stolpec">
                                <font-awesome-icon :icon="['fas', 'pencil-alt']" @click="showModal({component: 'evalvation/QuestionEditColumnFixTable', 
                                    data:{title:table_data.table3.structure.data.column2_title, description:table_data.table3.structure.data.column2_description, column_num:2, table_num:3}})" />
                            </span>

                            <span class="title">
                                {{ table_data.table3.structure.data.column2_title }}
                            </span>
                        </div>
                        <span class="description" v-if="table_data.table3.structure.data.column2_description != ''">
                            {{ table_data.table3.structure.data.column2_description }}
                        </span>
                    </div>
                    <div class="column column_header"> 
                        <div class="question_title_row">
                            <span v-if="build_mode && getUserType == 2" class="as_link icon" title="Uredi stolpec">
                                <font-awesome-icon :icon="['fas', 'pencil-alt']" @click="showModal({component: 'evalvation/QuestionEditColumnFixTable', 
                                    data:{title:table_data.table3.structure.data.column3_title, description:table_data.table3.structure.data.column3_description, column_num:3, table_num:3}})" />
                            </span>

                            <span class="title">
                                {{ table_data.table3.structure.data.column3_title }}
                            </span>
                        </div>
                        <span class="description" v-if="table_data.table3.structure.data.column3_description != ''">
                            {{ table_data.table3.structure.data.column3_description }}
                        </span>
                    </div>
                    <div class="column column_header"> 
                        <div class="question_title_row">
                            <span v-if="build_mode && getUserType == 2" class="as_link icon" title="Uredi stolpec">
                                <font-awesome-icon :icon="['fas', 'pencil-alt']" @click="showModal({component: 'evalvation/QuestionEditColumnFixTable', 
                                    data:{title:table_data.table3.structure.data.column4_title, description:table_data.table3.structure.data.column4_description, column_num:4, table_num:3}})" />
                            </span>

                            <span class="title">
                                {{ table_data.table3.structure.data.column4_title }}
                            </span>
                        </div>
                        <span class="description" v-if="table_data.table3.structure.data.column4_description != ''">
                            {{ table_data.table3.structure.data.column4_description }}
                        </span>
                    </div>
                    <div class="column column_header"> 
                        <div class="question_title_row">
                            <span v-if="build_mode && getUserType == 2" class="as_link icon" title="Uredi stolpec">
                                <font-awesome-icon :icon="['fas', 'pencil-alt']" @click="showModal({component: 'evalvation/QuestionEditColumnFixTable', 
                                    data:{title:table_data.table3.structure.data.column5_title, description:table_data.table3.structure.data.column5_description, column_num:5, table_num:3}})" />
                            </span>

                            <span class="title">
                                {{ table_data.table3.structure.data.column5_title }}
                            </span>
                        </div>
                        <span class="description" v-if="table_data.table3.structure.data.column5_description != ''">
                            {{ table_data.table3.structure.data.column5_description }}
                        </span>
                    </div>
                    <div class="column column_header highlighted"> 
                        <div class="question_title_row">
                            <span v-if="build_mode && getUserType == 2" class="as_link icon" title="Uredi stolpec">
                                <font-awesome-icon :icon="['fas', 'pencil-alt']" @click="showModal({component: 'evalvation/QuestionEditColumnFixTable', 
                                    data:{title:table_data.table3.structure.data.column6_title, description:table_data.table3.structure.data.column6_description, column_num:4, table_num:3}})" />
                            </span>

                            <span class="title">
                                {{ table_data.table3.structure.data.column6_title }}
                            </span>
                        </div>
                        <span class="description" v-if="table_data.table3.structure.data.column6_description != ''">
                            {{ table_data.table3.structure.data.column6_description }}
                        </span>
                    </div>
                    <div class="column column_header"> 
                        <div class="question_title_row">
                            <span v-if="build_mode && getUserType == 2" class="as_link icon" title="Uredi stolpec">
                                <font-awesome-icon :icon="['fas', 'pencil-alt']" @click="showModal({component: 'evalvation/QuestionEditColumnFixTable', 
                                    data:{title:table_data.table3.structure.data.column7_title, description:table_data.table3.structure.data.column7_description, column_num:4, table_num:3}})" />
                            </span>

                            <span class="title">
                                {{ table_data.table3.structure.data.column7_title }}
                            </span>
                        </div>
                        <span class="description" v-if="table_data.table3.structure.data.column7_description != ''">
                            {{ table_data.table3.structure.data.column7_description }}
                        </span>
                    </div>
                    <div class="column tools"></div>
                </div>

                <div v-if="!build_mode && table_data.table3.data">
                    <div class="row data" v-for="row in sorted_table3_data" :key="row.id"> 
                        <div class="column small">
                            <div>
                                <span class="page_num">{{row.column1_value}}</span>
                            </div>
                        </div>
                        <div class="column">
                            <div class="in_column">
                                <textarea v-if="row.reference_question_id != '0'"
                                     disabled rows="11" :value="(row.column2_value.substring(0, 250) + (row.column2_value.length > 250 ? '...' : ''))"></textarea>
                                <textarea v-else
                                     rows="11" :disabled="disable_input" 
                                     v-model="table_data.table3.data.data[row.id].column2_value" 
                                     @change="cellChanged(row.id, 2, 3, $event)"></textarea>
                                <div v-if="row.reference_question_id != '0'" class="as_link icon redirect_link" title="Na poglavje" @click="changePageId(row.page_id)">
                                    <font-awesome-icon :icon="['far', 'plus-square']" />
                                </div>
                            </div>
                        </div>
                        <div class="column">
                            <div>
                                <textarea rows="11" :disabled="disable_input" v-model="table_data.table3.data.data[row.id].column3_value" @change="cellChanged(row.id, 3, 3, $event)"></textarea>
                            </div>
                        </div>
                        <div class="column">
                            <div>
                                <textarea rows="11" :disabled="disable_input" v-model="table_data.table3.data.data[row.id].column4_value" @change="cellChanged(row.id, 4, 3, $event)"></textarea>
                            </div>
                        </div>
                        <div class="column">
                            <div>
                                <textarea rows="11" :disabled="disable_input" v-model="table_data.table3.data.data[row.id].column5_value" @change="cellChanged(row.id, 5, 3, $event)"></textarea>
                            </div>
                        </div>
                        <div class="column highlighted">
                            <div>
                                <textarea rows="11" :disabled="disable_input" v-model="table_data.table3.data.data[row.id].column6_value" @change="cellChanged(row.id, 6, 3, $event)"></textarea>
                            </div>
                        </div>
                        <div class="column">
                            <div>
                                <textarea rows="11" :disabled="disable_input" v-model="table_data.table3.data.data[row.id].column7_value" @change="cellChanged(row.id, 7, 3, $event)"></textarea>
                            </div>
                        </div>
                        <div class="column tools">
                            <div class="as_link icon" title="Pobriši vrstico" v-if="!disable_input && !showRemoveRow('table3', row.id)">
                                <font-awesome-icon :icon="['far', 'trash-alt']" @click="showModal({component: 'evalvation/DeleteRowTable', data:{table:3, row_id:row.id, additional_programs:Array.from(programs_id).slice(1)}})" />
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="row data" v-for="(row, key) in {1:{}, 2:{}}" :key="key">    
                    <div class="column small">
                        <div>
                            <div></div>
                        </div>
                    </div>
                    <div class="column">
                        <div>
                            <textarea rows="4" :disabled="build_mode"></textarea>
                        </div>
                    </div>
                    <div class="column">
                        <div>
                            <textarea rows="4" :disabled="build_mode"></textarea>
                        </div>
                    </div>
                    <div class="column">
                        <div>
                            <textarea rows="4" :disabled="build_mode"></textarea>
                        </div>
                    </div>
                    <div class="column">
                        <div>
                            <textarea rows="4" :disabled="build_mode"></textarea>
                        </div>
                    </div>
                    <div class="column">
                        <div>
                            <textarea rows="4" :disabled="build_mode"></textarea>
                        </div>
                    </div>
                    <div class="column">
                        <div>
                            <textarea rows="4" :disabled="build_mode"></textarea>
                        </div>
                    </div>
                    <div class="column tools">
                    
                    </div>
                </div>

                <div class="row add-data" v-if="!disable_input">
                    <div class="as_link icon" title="Dodaj vrstico" @click="addTableRow(3)">
                        <font-awesome-icon :icon="['far', 'plus-square']" /> Dodaj vrstico
                    </div>
                </div>
            </div>
        </div>

        <div>
            <p>* Dejavnik znotraj organizacije (institucije, enote, službe…) je dejavnik, na katerega imamo vpliv (npr. neustrezna organizacija dela). Po drugi strani, na dejavnik zunaj organizacije (institucije, enote, službe…) načeloma nimamo vpliva (npr. pričakovana sprememba zakonodaje). V kolikor smo v dilemi, ali gre za notranji ali zunanji dejavnik, presojamo po prevladujočem vplivu.</p>
            <p>** V kolikor ste tekom študijskega leta že sproti izvedli številne kratkoročne ukrepe, potem se pri načrtovanju prihodnjih osredotočite na tiste ukrepe, ki naslavljajo dolgoročne cilje in na ta način zagotovitve povezavo med ugotovitvami samoevalvacije posameznih vidikov smernic in načrtovanimi ukrepi.</p>
        </div>
        <br>
        <!-- <br>
        <br>

        <div class="evalvation_question_holder question">
            <div class="question_title_row">
                <span class="question_title">
                    <b>Ostale priponke</b>
                </span>
            </div>

            <div class="question_description">
                Če želite naložiti še kakšno priponko, ki ne spada v posamezna poglavja, jo lahko naložite tu.
            </div>

            <div>
                <div v-if="!disable_input" class="sections_list_upload for_question">
                    <div class="for_question button gray" @click="showModal({component: 'attachments/EditAttachments', 
                        data:{upload_type: 4, question_id: -1, program_id: get_one_program_id, additional_programs: Array.from(programs_id).slice(1)}})">
                        Priponke 
                        <span v-show="program_attachments > 0">({{program_attachments}})</span>
                    </div>
                </div>
            </div>
        </div> -->

    </div>
</template>


<script>
	import { mapActions } from 'vuex';

	export default {
		name: 'EvalvationQuestionnaireTables',
		components: {
        },
        data: function() {
			return {
                status_dropdown_items:[
                    'realiziran', 'delno realiziran', 'ostaja na ravni predloga', 'opuščen'
                ]
			}
        },
        props: {
            build_mode: {
                type: Boolean,
                required: true
            }
        },
        computed: {
            // Data of table for program
			table_data(){
                if(this.$store.getters.table_details.table1)
                    return this.$store.getters.table_details;
                else
                    return {};
            },

            //Sort table 3 data 
            sorted_table3_data: function () {
                if(this.table_data.table3 && this.table_data.table3.data.data){
                    let table3_data = Object.values(this.table_data.table3.data.data);

                    let filtered = table3_data.filter((row) => {
                        return ((row.reference_row_id == 0 && row.reference_question_id == 0)
                            || (row.column2_value !== 'Za to področje ni predvidenih ukrepov.'
                            && row.column2_value !== '"Za to področje ni predvidenih ukrepov."'
                            && row.column2_value !== '/' && row.column2_value !== ''));
                    });

                    return this.sort_table_by_page(filtered);
                }
                else
                    return {};
            },

            //Sort table 1 data 
            sorted_table1_data: function () {
                if(this.table_data.table1 && this.table_data.table1.data.data){
                    let table1_data = Object.values(this.table_data.table1.data.data);

                    let filtered = table1_data.filter((row) => {
                        return ((row.reference_row_id == 0 && row.reference_question_id == 0)
                            || (row.column2_value !== 'Za to področje ni predvidenih ukrepov.'
                            && row.column2_value !== '"Za to področje ni predvidenih ukrepov."'
                            && row.column2_value !== '/' && row.column2_value !== ''));
                    });

                    return this.sort_table_by_page(filtered);
                }
                else
                    return {};
            },

            //get IDs of all programs for this questionnaire
            programs_id(){
                return this.$route.params.programs.toString().split(",");
            },

            get_one_program_id(){
                if(Array.isArray(this.programs_id))
                    return this.programs_id[0];
                return this.programs_id;
            },

            // Get program data
            program_data(){
                if(this.build_mode)
                    return {};

                //if user is admin, pull program data from admins program object
                if(this.getUserType > 1 && (this.programs_id.length == 1 || Number.isInteger(this.programs_id))){
                    return this.$store.getters.evalvation_program_detail;
                }
                //if user not admin, pull program data from program user object
                else{
                    if(Object.keys(this.$store.getters.evalvation_programs_user).length !== 0){
                        for(let id of this.programs_id){
                            return this.$store.getters.evalvation_programs_user[id];
                        }
                    }
                    return '';
                }
            },

            //get data for this questionnaire
            questionnaire_data(){
                if(this.build_mode)
                    return {};
                else {
                    return this.$store.getters.questionnaire_data;
                }
            },

            //get attachment data of program
            program_attachments(){
                if(this.questionnaire_data && this.questionnaire_data.attachment_count)
                    return this.questionnaire_data.attachment_count;
                return 0;
            },

            // Get loged user type
			getUserType(){
				return this.$store.getters.getUser.type;
            },

            disable_input(){
                return this.getUserType == 3 || (this.build_mode || (this.getUserType < 2 && 
                (this.program_data.finished == 1 || this.program_data.submitted == 1 || this.program_data.disabled == 1 || this.program_data.ly_extended_finished == 1)));
            },

            year() {
                return this.$store.getters.year;
            },
        }, 
        methods: { 		
            ...mapActions([
                'showModal',
                'insertTableRow',
                'saveTableCell',
                'changeActivePageQ'
            ]),

            sort_table_by_page(table_data){
                let col = Intl.Collator("sl");

                return table_data.sort(function(a, b) {          

                        if (!b.column1_value) {
                            if (!a.column1_value) {
                                //if none is bounded to page, sort by id
                                return a.id - b.id;
                            }
                            //if this is nonbounded compared to bounded, put it after
                            return -1;
                        }

                        //if this is bounded one copare to nonbounded, put it before
                        if (!a.column1_value) {
                            return 1;
                        }

                        //both are bounded to same page, sort by id
                        if (a.column1_value === b.column1_value) {
                            return a.id - b.id;
                        }

                        //both bounded to page but different, sort by page name
                        return (col.compare(a.column1_value, b.column1_value) > 0) || !a.column1_value ? 1 : -1;

                    });
            },

            addTableRow(id){
                //let additional_programs = Array.from(this.programs_id).slice(1);
                this.insertTableRow(id);
            },

            // Check if it is the only row in table
            showRemoveRow(question_id, row_id){
                return this.table_data[question_id].data.data[row_id].reference_question_id > 0;
            },

            // When cell is changed
            cellChanged(row_id, column_id, table_number, event){
                let newValue = event.target.value;
                let additional_programs = Array.from(this.programs_id).slice(1);
                this.saveTableCell({row_id:row_id, column:column_id, value:newValue, table: table_number, additional_programs: additional_programs});
            },

            //Change active page - redirect to active page
            changePageId(page_id){
                this.changeActivePageQ(page_id);

                //timeout needed because new browsers are so fast that sometimes scrollTo() action fires before browsers default scroll to html anchor event
                //scroll to bottom
                setTimeout(function() {window.scrollTo({top: document.body.scrollHeight, behavior: 'smooth'});},50);
            },
        },
	}
</script>


<style lang="scss" scoped>
	@import "../../scss/style.scss";

	/* Transicije */
	.fade-enter-active{
		transition: opacity 0.3s;
	}
	.fade-leave-active{
		transition: opacity 0.2s;
	}
	.fade-enter,
	.fade-leave-to {
		opacity: 0;
	}
</style>

import axios from 'axios'
import Vue from 'vue';
import * as general_mutations from '../mutations';
import * as general_state from '../store';
import router from '../../router/router'

const state = {
    // Details of this program
	evalvation_program_detail: {},

	// Details of this department
	evalvation_department_detail: {},

	// Details of active questionnaire
	evalvation_questionnaire_detail: {},

	// Details of internal questionnaire (type 3)
	evalvation_internal_questionnaire_detail: {},

	// ID of active page in questionnaire
	active_q_page_id: '',

	// Answers for active program questionnaire
	questionnaire_data: {},

	// Answers for active multiple programs questionnaire
	questionnaire_multiple_data: {},

	// Details of table for this program
	table_details: {},

    // List of image types for questions
    question_image_types: {},

	//Array of faculties, that have permission to create internal questionnaire
	faculties_internal_permission: [19]
};


const getters = {
	// Return program detail
	evalvation_program_detail: store => {
		return state.evalvation_program_detail;
	},

	//Array of faculties, that have permission to create internal questionnaire
	faculties_internal_permission: store => {
		return state.faculties_internal_permission;
	},

	// Return department detail
	evalvation_department_detail: store => {
		return state.evalvation_department_detail;
	},

	// Return questionnaire detail
	evalvation_questionnaire_detail: store => {
		return state.evalvation_questionnaire_detail;
	},

	// Return internal questionnaire detail
	evalvation_internal_questionnaire_detail: store => {
		return state.evalvation_internal_questionnaire_detail;
	},

	// Return active page in questionnaire
	active_q_page_id: store => {
		return state.active_q_page_id;
	},

	// Return answers of questionnaire of active program
	questionnaire_data: store => {
		return state.questionnaire_data;
	},

	// Return answers of questionnaire of active program
	questionnaire_multiple_data: store => {
		return state.questionnaire_multiple_data;
	},

	// Return details of table for this program
	table_details: store => {
		return state.table_details;
	},

    // Get list of image types for questions
	question_image_types: store => {
		return state.question_image_types;
	},
};


const mutations = {
	// Prepare list of all programs in year
    getProgramDetail: (state, payload) => {
		state.evalvation_program_detail = payload;
	},

	// Prepare list of all programs in year
    getDepartmentDetail: (state, payload) => {
		state.evalvation_department_detail = payload;
	},

	// Prepare list of questionnaire for program
    getQuestionnaireDetails: (state, payload) => {
		state.evalvation_questionnaire_detail = payload;

		//get first page id
        for (var page_id_k in state.evalvation_questionnaire_detail.pages) {
			if(state.evalvation_questionnaire_detail.pages[page_id_k].order_number == 1) {
				var page_id = page_id_k;
				break;
			}
        }

        state.active_q_page_id = page_id;
	},

	// Prepare list of internal questionnaire for program
	getInternalQuestionnaireDetails: (state, payload) => {
		state.evalvation_internal_questionnaire_detail = payload;
	},

	// Refresh list of questionnaire for program
	refreshQuestionnaireDetails: (state, payload) => {
		if(state.evalvation_questionnaire_detail.pages[state.active_q_page_id])
			state.evalvation_questionnaire_detail = payload;
		else
			state.evalvation_internal_questionnaire_detail = payload;
	},

	// Add page in questionnaire
	addQuestionnairePage: (state, payload) => {
		if(!payload.internal){
			state.evalvation_questionnaire_detail = payload.data;
		
			//get last page id
			for (var page_id_k in state.evalvation_questionnaire_detail.pages) { 
				var page_id = page_id_k;
			} 
		}
		//internal questionnaire
		else {
			state.evalvation_internal_questionnaire_detail = payload.data;
		
			//get last page id
			for (var page_id_k in state.evalvation_internal_questionnaire_detail.pages) { 
				var page_id = page_id_k;
			} 
		}

        state.active_q_page_id = page_id;
	
	},

	// Edit page in questionnaire
	editQuestionnairePage: (state, payload) => {
		let active_q_page_id = state.active_q_page_id;

		if(state.evalvation_questionnaire_detail.pages[active_q_page_id]){
			state.evalvation_questionnaire_detail.pages[active_q_page_id].name = payload.name;
			state.evalvation_questionnaire_detail.pages[active_q_page_id].description = payload.description;
		}
		else{
			state.evalvation_internal_questionnaire_detail.pages[active_q_page_id].name = payload.name;
			state.evalvation_internal_questionnaire_detail.pages[active_q_page_id].description = payload.description;
		}
	},

	// Set answers for active program questionnaire
	deleteQuestionnairePage: (state) => {
        let active_q_page_id = state.active_q_page_id;

		if(state.evalvation_questionnaire_detail.pages[active_q_page_id]){
        	delete state.evalvation_questionnaire_detail.pages[active_q_page_id];
		}
		else{
			delete state.evalvation_internal_questionnaire_detail.pages[active_q_page_id];
		}

		// Set first page as active      
		for (var page_id_k in state.evalvation_questionnaire_detail.pages) { 
			if(state.evalvation_questionnaire_detail.pages[page_id_k].order_number == 1) {
				var page_id = page_id_k;
				break;
			}
		} 

        state.active_q_page_id = page_id;
	},

	// Change active page
	changeActivePageQ: (state, payload) => {
		state.active_q_page_id = payload;
	},

	// Add question in page
	addQuestionnaireQuestion: (state, payload) => {
		if(state.evalvation_questionnaire_detail.pages[state.active_q_page_id])
			state.evalvation_questionnaire_detail = payload;
		else
			state.evalvation_internal_questionnaire_detail = payload;
	},

	// Edit question in page
	editQuestionnaireQuestion: (state, payload) => {
		if(state.evalvation_questionnaire_detail.pages[state.active_q_page_id]){
			state.evalvation_questionnaire_detail.pages[state.active_q_page_id].questions[payload.order_number].title = payload.title;
			state.evalvation_questionnaire_detail.pages[state.active_q_page_id].questions[payload.order_number].description = payload.description;
			state.evalvation_questionnaire_detail.pages[state.active_q_page_id].questions[payload.order_number].break = payload.break ? '1' : '0';
			state.evalvation_questionnaire_detail.pages[state.active_q_page_id].questions[payload.order_number].stopnja = payload.stopnja;
			state.evalvation_questionnaire_detail.pages[state.active_q_page_id].questions[payload.order_number].vrsta = payload.vrsta;
		}
		else{
			state.evalvation_internal_questionnaire_detail.pages[state.active_q_page_id].questions[payload.order_number].title = payload.title;
			state.evalvation_internal_questionnaire_detail.pages[state.active_q_page_id].questions[payload.order_number].description = payload.description;
			state.evalvation_internal_questionnaire_detail.pages[state.active_q_page_id].questions[payload.order_number].break = payload.break ? '1' : '0';
			state.evalvation_questionnaire_detail.pages[state.active_q_page_id].questions[payload.order_number].stopnja = payload.stopnja;
			state.evalvation_questionnaire_detail.pages[state.active_q_page_id].questions[payload.order_number].vrsta = payload.vrsta;
		}
	},

	// Delete question in page
	deleteQuestionnaireQuestion: (state, payload) => {
		if(state.evalvation_questionnaire_detail.pages[state.active_q_page_id]){
			var q_num = Object.keys(state.evalvation_questionnaire_detail.pages[state.active_q_page_id].questions).pop();
			var questions = state.evalvation_questionnaire_detail.pages[state.active_q_page_id].questions;
		}
		//internal questionnaire
		else{
			var q_num = Object.keys(state.evalvation_internal_questionnaire_detail.pages[state.active_q_page_id].questions).pop();
			var questions = state.evalvation_internal_questionnaire_detail.pages[state.active_q_page_id].questions;
		}

		// Reorder questions
		for(let i = payload.order_number; i < q_num; i++){
			delete questions[i];
			questions[i] = questions[1+(+i)];
			questions[i].order_number = i;
		}

		//delete last
		delete questions[q_num];

		// Force refresh - VUE SET...?
		if(state.evalvation_questionnaire_detail.pages[state.active_q_page_id]){
			var temp = state.evalvation_questionnaire_detail;
			state.evalvation_questionnaire_detail = {};
			state.evalvation_questionnaire_detail = temp;
		}
		//internal questionnaire
		else{
			var temp = state.evalvation_internal_questionnaire_detail;
			state.evalvation_internal_questionnaire_detail = {};
			state.evalvation_internal_questionnaire_detail = temp;
		}
	},

	//Get answers data for questionare of active program
	getQuestionnaireData: (state, payload) => {
		state.questionnaire_data = payload;
	},

	//Get special answers data for questionare of active multiple programs
	getMultipleQuestionData: (state, payload) => {
		state.questionnaire_multiple_data[payload.question_id] = payload.data;
		// Force refresh - VUE SET...?
		var temp = state.questionnaire_multiple_data;
		state.questionnaire_multiple_data = {};
		state.questionnaire_multiple_data = temp;
	},

	//save question answer
	saveQuestionData: (state, payload) => {
		if(!state.questionnaire_data[payload.question_id]){
			Vue.set(state.questionnaire_data, payload.question_id, {});
		}
		if(!state.questionnaire_data[payload.question_id].answer){
			Vue.set(state.questionnaire_data[payload.question_id], 'answer', '');
		}
		if(!state.questionnaire_data[payload.question_id]){
			Vue.set(state.questionnaire_data[payload.question_id], 'attachment_count', 0);
		}
		state.questionnaire_data[payload.question_id].answer = payload.value;
	},

	//save question answer
	saveExtendedProgram: (state, payload) => {
		Vue.set(state.evalvation_program_detail, 'extended', payload.extended);
	},

	//save question multiple program answer
	saveQuestionMultipleData: (state, payload) => {
		state.questionnaire_multiple_data[payload.question_id][payload.program_id] = payload.value;
	},

	//save break answer
	saveBreakData: (state, payload) => {
		if(!state.questionnaire_data[payload.question_id].breaks)
			state.questionnaire_data[payload.question_id].breaks = [];
		state.questionnaire_data[payload.question_id].breaks[payload.break_id] = payload.value;
	},

	// Update questionnaire intro
	updateQuestionnaireIntro: (state, payload) => {
		state.evalvation_questionnaire_detail.intro = payload;
	},

	// Update table details
	getTableDetails: (state, payload) => {
		state.table_details = payload;
	},

	// Edit fixed table column
	editQuestionFixTableColumn: (state, payload) => {
		let table = 'table'+payload.table_number;
		let column_title = 'column'+payload.column_number+'_title';
		let column_description = 'column'+payload.column_number+'_description';
		state.table_details[table].structure.data[column_title] = payload.title;
		state.table_details[table].structure.data[column_description] = payload.description;
	},

	// Edit table column
	editQuestionTableColumn: (state, payload) => {
		if(state.evalvation_questionnaire_detail.pages[state.active_q_page_id]){
			state.evalvation_questionnaire_detail.pages[state.active_q_page_id].questions[payload.q_order_num].columns[payload.c_order_num].name = payload.name;
			state.evalvation_questionnaire_detail.pages[state.active_q_page_id].questions[payload.q_order_num].columns[payload.c_order_num].description = payload.description;
		}
		//internal questionnaire
		else{
			state.evalvation_internal_questionnaire_detail.pages[state.active_q_page_id].questions[payload.q_order_num].columns[payload.c_order_num].name = payload.name;
			state.evalvation_internal_questionnaire_detail.pages[state.active_q_page_id].questions[payload.q_order_num].columns[payload.c_order_num].description = payload.description;
		}
	},

	// Remove table column
	removeQuestionTableColumn: (state, payload) => {
		if(state.evalvation_questionnaire_detail.pages[state.active_q_page_id]){
			delete state.evalvation_questionnaire_detail.pages[state.active_q_page_id].questions[payload.q_order_num].columns[payload.c_order_num];

			// Force refresh - VUE SET...?
			var temp = state.evalvation_questionnaire_detail;
			state.evalvation_questionnaire_detail = {};
			state.evalvation_questionnaire_detail = temp;
		}
		//internal questionnaire
		else{
			delete state.evalvation_internal_questionnaire_detail.pages[state.active_q_page_id].questions[payload.q_order_num].columns[payload.c_order_num];

			// Force refresh - VUE SET...?
			var temp = state.evalvation_internal_questionnaire_detail;
			state.evalvation_internal_questionnaire_detail = {};
			state.evalvation_internal_questionnaire_detail = temp;
		}
	},

	// Remove table row
	removeQuestionTableRow: (state, payload) => {
		delete state.questionnaire_data[payload.question_id].table_rows[payload.row_id];

		if(Object.keys(state.questionnaire_data[payload.question_id].table_rows).length == 0)
			delete state.questionnaire_data[payload.question_id].table_rows;

		// Force refresh - VUE SET...?
		var temp = state.questionnaire_data;
		state.questionnaire_data = {};
		state.questionnaire_data = temp;
	},

	// Add table row
	addQuestionTableRow: (state, payload) => {
		let columns = state.evalvation_questionnaire_detail.pages[state.active_q_page_id] ? 
			Object.values(state.evalvation_questionnaire_detail.pages[state.active_q_page_id].questions[payload.q_order_num].columns) :
			Object.values(state.evalvation_internal_questionnaire_detail.pages[state.active_q_page_id].questions[payload.q_order_num].columns);

		let last_year_rows = state.questionnaire_data[payload.question_id] ? state.questionnaire_data[payload.question_id].table_rows_last_year : null;
		let value = payload.value ? payload.value : '';
		let clear_table = payload.clear_table ? payload.clear_table : false;

		//remove all data for table
		if(clear_table)
			state.questionnaire_data[payload.question_id] = {table_rows: {}};
			
		for(let column of columns){
			//if data for that question does not exist yet (first row added)
			if(!state.questionnaire_data[payload.question_id] || !state.questionnaire_data[payload.question_id].table_rows){
				state.questionnaire_data[payload.question_id] = {table_rows: {}};
				state.questionnaire_data[payload.question_id].table_rows[payload.row_id] = {table_columns: {}};
			}
			if(!state.questionnaire_data[payload.question_id].table_rows[payload.row_id])
				state.questionnaire_data[payload.question_id].table_rows[payload.row_id] = {table_columns: {}};

			state.questionnaire_data[payload.question_id].table_rows[payload.row_id].table_columns[column.id] = value;

			//if last yea data exists
			if(last_year_rows){
				state.questionnaire_data[payload.question_id].table_rows_last_year = last_year_rows;
			}
		}

		// Force refresh - VUE SET...?
		var temp = state.questionnaire_data;
		state.questionnaire_data = {};
		state.questionnaire_data = temp;
	},

    // Set list of image types
	getQuestionImageTypes: (state, payload) => {
		state.question_image_types = payload;
	},
};


const actions = {
	// Change active page
	changeActivePageQ: ({commit, dispatch}, page_id) => {
		if(page_id == 'RU'){
			dispatch('getTableDetails');
		}
		commit('changeActivePageQ', page_id);
	},

	// Prepare table detail
	getTableDetails: ({commit}) => {
		general_mutations.showModalWorking(general_state.state);

		let programs = router.currentRoute.params.programs ? router.currentRoute.params.programs.toString().split(",") : "";
		axios.get('?module=evalvation_table&action=get_data&questionaire_id='+state.evalvation_questionnaire_detail.id+'&program_id='+programs[0])
			.then(
				res => {
					if(res.data.error){				
						general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: res.data.error})
					}
					else{
						commit('getTableDetails', res.data);
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},	

	// Insert table row
	insertTableRow: ({commit, dispatch}, payload) => {
		general_mutations.showModalWorking(general_state.state);

		let table_number = payload;
		let programs = router.currentRoute.params.programs ? router.currentRoute.params.programs.toString().split(",") : "";

		payload = {additional_programs: Array.from(programs).slice(1)};
		axios.post('?module=evalvation_table&action=insert_row&questionaire_id='+state.evalvation_questionnaire_detail.id+'&program_id='+programs[0]+'&table_number='+table_number, payload)
			.then(
				res => {
					if(res.data.error){				
						general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: res.data.error})
					}
					else{
						dispatch('getTableDetails');
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},	
	
	// Delete table row
	deleteTableRow: ({commit, dispatch}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		
		let programs = router.currentRoute.params.programs ? router.currentRoute.params.programs.toString().split(",") : "";
		axios.post('?module=evalvation_table&action=delete_row&row_id='+payload.row_id+'&program_id='+programs[0]+'&table_number='+payload.table_number, payload)
			.then(
				res => {
					if(res.data.error){				
						general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: res.data.error})
					}
					else{
						dispatch('getTableDetails');
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},	

	// Update table cell
	saveTableCell: ({commit}, payload) => {
		//general_mutations.showModalWorking(general_state.state);

		let programs = router.currentRoute.params.programs ? router.currentRoute.params.programs.toString().split(",") : "";
		axios.post('?module=evalvation_table&action=save_cell&questionaire_id='+state.evalvation_questionnaire_detail.id+'&row_id='+payload.row_id+
			'&program_id='+programs[0]+'&table_number='+payload.table+'&column_number='+payload.column, payload)
			.then(
				res => {
					if(res.data.error){				
						//general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: res.data.error})
					}
					else{
						//commit('saveTableCell', payload);
						//general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});			
	},

	// Prepare program detail
	getProgramDetail: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.post('?module=evalvation_program&action=get_details&program_id='+payload.program_id, payload)
			.then(
				res => {
					if(res.error){			
						general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: res.data.error})
					}
					else{	
						commit('getProgramDetail', res.data);
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},		

	// Prepare program detail
	getDepartmentDetail: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.get('?module=evalvation_oddelek&action=get_details&oddelek_id='+payload)
			.then(
				res => {
					if(res.error){			
						general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: res.data.error})
					}
					else{
						commit('getDepartmentDetail', res.data);
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},	

	// Prepare questionnaire detail
	getQuestionnaireDetails: ({commit, dispatch}, payload) => {
		general_mutations.showModalWorking(general_state.state);

		let params = '';
		if(payload.id)
			params = 'questionaire_id='+payload.id
		else if(payload.type)
			params = 'type='+payload.type+'&year='+general_state.state.year

		axios.get('?module=evalvation_questionaire&action=get_questionaire_details&'+params)
			.then(
				res => {
					if(res.data.error){				
						general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: res.data.error})
					}
					else{
						//if internal questionnaire details
						if((typeof payload.type === 'string' || payload.type instanceof String) && 
								(payload.type.includes('3_') || payload.type.includes('5_'))){
							commit('getInternalQuestionnaireDetails', res.data);
							general_mutations.hideModalWorking(general_state.state);
						}
						//normal questionnaire and departmnents
						else{
							commit('getQuestionnaireDetails', res.data);
							general_mutations.hideModalWorking(general_state.state);
						}

						//get internal questionnaire details
						if(payload.type == 1 || payload.type == 4){
							let program_id = router.currentRoute.params.programs ? router.currentRoute.params.programs.toString().split(",")[0] : "";
							let faculty_id = general_state.state.faculty.id;
							let pre_id = payload.type == 1 ? '3_' : '5_';
							if(program_id){
								faculty_id=state.evalvation_program_detail.faculty_id;
							}

							dispatch('getQuestionnaireDetails', {type: pre_id+faculty_id });
						}

						//if there is multple programs fill out, get special questions
						if(payload.type == 1 && router.currentRoute.params.programs && router.currentRoute.params.programs.toString().split(",").length > 1){
							let q_detail = state.evalvation_questionnaire_detail;
							//get first question id of first page
							let question_id = q_detail.pages[Object.keys(q_detail.pages)[0]].questions[Object.keys(q_detail.pages[Object.keys(q_detail.pages)[0]].questions)[0]].id;

							dispatch('getMultipleQuestionData', {question_id: question_id, program_ids: router.currentRoute.params.programs.toString().split(",") });
						}
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},	

	// Add page in questionnaire
	addQuestionnairePage: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);

		let questionnaire_id = payload.questionnaire_id ? payload.questionnaire_id : state.evalvation_questionnaire_detail.id;
		axios.post('?module=evalvation_questionaire&action=add_page&questionaire_id='+questionnaire_id, payload)
			.then(
				res => {
					if(res.data.error){				
						general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: res.data.error})
					}
					else{
						let pass = {data: res.data};
						//if internal questionnaire
						if(payload.questionnaire_id)
							pass.internal = true;
						commit('addQuestionnairePage', pass);
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},	

	// Edit page in questionnaire
	editQuestionnairePage: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.post('?module=evalvation_questionaire&action=edit_page&page_id='+state.active_q_page_id, payload)
			.then(
				res => {
					if(res.data.error){				
						general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: res.data.error})
					}
					else{
						commit('editQuestionnairePage', payload);
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},	

	// Delete page in questionnaire
	deleteQuestionnairePage: ({commit}) => {
		general_mutations.showModalWorking(general_state.state);
		axios.post('?module=evalvation_questionaire&action=delete_page&page_id=' + state.active_q_page_id)
			.then(
				res => {
					if(res.data.error){				
						general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: res.data.error})
					}
					else{
						commit('deleteQuestionnairePage');
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},	

	// Add question in questionnaire
	addQuestionnaireQuestion: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.post('?module=evalvation_questionaire&action=add_question&page_id=' + state.active_q_page_id, payload)
			.then(
				res => {
					if(res.data.error){				
						general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: res.data.error})
					}
					else{
						commit('addQuestionnaireQuestion', res.data);
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},	

	// Delete question in questionnaire
	deleteQuestionnaireQuestion: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.post('?module=evalvation_questionaire&action=delete_question&question_id=' + payload.id )
			.then(
				res => {
					if(res.data.error){				
						general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: res.data.error})
					}
					else{
						commit('deleteQuestionnaireQuestion', payload);
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},	

	// Edit question in questionnaire
	editQuestionnaireQuestion: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.post('?module=evalvation_questionaire&action=edit_question&question_id=' + payload.id, payload)
			.then(
				res => {
					if(res.data.error){				
						general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: res.data.error})
					}
					else{
						//commit('editQuestionnaireQuestion', payload);
                        commit('refreshQuestionnaireDetails', res.data);
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},	

	// Get questionnaire answers for department
	getDepartmentQuestionnaireData: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.get('?module=evalvation_data&action=get_questionaire_data_oddelek&oddelek_id=' + payload)
			.then(
				res => {
					if(res.data.error){				
						general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: res.data.error})
					}
					else{
						commit('getQuestionnaireData', res.data);
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},	

	// Get questionnaire answers for program
	getQuestionnaireData: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.get('?module=evalvation_data&action=get_questionaire_data&program_id=' + payload)
			.then(
				res => {
					if(res.data.error){				
						general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: res.data.error})
					}
					else{
						commit('getQuestionnaireData', res.data);
						general_mutations.hideModalWorking(general_state.state);

					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},	

	// Get data for specific question
	getMultipleQuestionData: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.post('?module=evalvation_data&action=get_question_data&question_id='+payload.question_id, payload)
			.then(
				res => {
					if(res.data.error){				
						general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: res.data.error})
					}
					else{
						commit('getMultipleQuestionData', {question_id: payload.question_id, data: res.data.programs});
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},

	// Save answer
	saveQuestionData: ({commit}, payload) => {
		//general_mutations.showModalWorking(general_state.state);

		//save in vue object first, to avoid "jumping" because of waiting on promise
		if(payload.multiple)
			commit('saveQuestionMultipleData', payload);
		else
			commit('saveQuestionData', payload);

		axios.post('?module=evalvation_data&action=save_question_data&question_id='+payload.question_id+'&program_id=' + payload.program_id, payload)
			.then(
				res => {
					if(res.data.error){				
						//general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: res.data.error})
					}
					else{
						//general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});			
	},	

	// Save department answer
	saveDepartmentQuestionData: ({commit}, payload) => {
		//general_mutations.showModalWorking(general_state.state);

		//save in vue object first, to avoid "jumping" because of waiting on promise
		commit('saveQuestionData', payload);

		axios.post('?module=evalvation_data&action=save_oddelek_data&question_id='+payload.question_id+'&oddelek_id=' + payload.department_id, payload)
			.then(
				res => {
					if(res.data.error){		
						//general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: res.data.error})
					}
					else{
						//general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});			
	},	

	// Save extended program
	saveExtendedProgram: ({commit}, payload) => {
		//save in vue object first, to avoid "jumping" because of waiting on promise
		commit('saveExtendedProgram', payload);

		axios.post('?module=evalvation_program&action=save_program_extended', payload)
			.then(
				res => {
					if(res.data.error){		
						//general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: res.data.error})
					}
				}
			)
			.catch(error => {
				console.log(error);
				general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
			});			
	},	

	//save break answer
	saveBreakData: ({commit}, payload) => {
		//general_mutations.showModalWorking(general_state.state);
		axios.post('?module=evalvation_data&action=save_break_data&question_id='+payload.question_id+'&break_id=' + payload.break_id, payload)
			.then(
				res => {
					if(res.data.error){				
						//general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: res.data.error})
					}
					else{
						commit('saveBreakData', payload);
						//general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});			
	},	

	// Copy questionnaire
	copyQuestionnaire: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.get('?module=evalvation_questionaire&action=copy_questionaire&year_from='+payload.year_from+'&year_to='+general_state.state.year+'&type_from='+payload.type)
			.then(
				res => {
					if(res.data.error){				
						general_mutations.hideModalWorking(general_state.state);
						if(res.data.error == 'No questionaire id')
							general_mutations.showModal(general_state.state, {component: 'ModalError', data: 'Obrazec v tem letu ne obstaja'})
						else
							general_mutations.showModal(general_state.state, {component: 'ModalError', data: res.data.error})
					}
					else{
						commit('getQuestionnaireDetails', res.data);
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});
	},	

	// Create new questionnaire
	createNewQuestionnaire: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.get('?module=evalvation_questionaire&action=create_new_questionaire&year='+general_state.state.year+'&type='+payload.type)
			.then(
				res => {
					if(res.data.error){				
						general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: res.data.error})
					}
					else{
						//if internal questionnaire details
						if((typeof payload.type === 'string' || payload.type instanceof String) && (payload.type.includes('3_') || payload.type.includes('5_'))){
							commit('getInternalQuestionnaireDetails', res.data);
							general_mutations.hideModalWorking(general_state.state);
							if(payload.create_page){
								general_mutations.showModal(general_state.state, {component: 'evalvation/CreateQuestionnairePage', data: {questionnaire_id: res.data.id}});
							}
						}
						else{
							commit('getQuestionnaireDetails', res.data);
							general_mutations.hideModalWorking(general_state.state);
						}
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});
	},	

	// Update questionnaire intro
	updateQuestionnaireIntro: ({commit}, payload) => {
		axios.post('?module=evalvation_questionaire&action=save_questionaire_intro&questionaire_id='+state.evalvation_questionnaire_detail.id, payload)
			.then(
				res => {
					if(!res.data.success){				
						var error = res.data.error ? res.data.error : payload.data;
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: error})
					}
					else{
						commit('updateQuestionnaireIntro', payload.value);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});			
	},

	// Export questionnaire
	exportQuestionnaire: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);

		let program = payload.program_id ? '&program_id='+payload.program_id : '';
		let department = payload.department_id ? '&oddelek_id='+payload.department_id : '';
		let export_mode = payload.export_mode ? '&export_mode='+payload.export_mode : '';

		axios.post('?module=export&action=get_evalvation_export&questionaire_id='+payload.questionnaire_id+'&format='+payload.format+
			'&faculty='+general_state.state.faculty.id+program+department+export_mode, payload)
			.then(
				res => {
					if(!res.data.success){		
						general_mutations.hideModalWorking(general_state.state);		
						var error = res.data.error ? res.data.error : payload.data;
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: error})
					}
					else{
						general_mutations.hideModalWorking(general_state.state);
						window.open(res.data.export_link); 
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},

	// Export questionnaire level for all programs
	exportQuestionnaireLevelAllPrograms: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.get('?module=export&action=get_evalvation_export_page_all_programs&questionaire_id='+payload.questionnaire_id+'&format='+payload.format+
			'&faculty='+payload.faculty_id+'&level_id='+payload.level_id+'&year='+general_state.state.year)
			.then(
				res => {
					if(!res.data.success){				
						general_mutations.hideModalWorking(general_state.state);
						var error = res.data.error ? res.data.error : payload.data;
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: error})
					}
					else{
						general_mutations.hideModalWorking(general_state.state);
						window.open(res.data.export_link); 
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},

	// Add column in table question
	addQuestionTableColumn: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.post('?module=evalvation_questionaire&action=add_column&question_id=' + payload.question_id, payload)
			.then(
				res => {
					if(res.data.error){				
						general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: res.data.error})
					}
					else{
						commit('refreshQuestionnaireDetails', res.data);
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},	

	// Edit column in table question
	editQuestionTableColumn: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.post('?module=evalvation_questionaire&action=edit_column&column_id=' + payload.column_id, payload)
			.then(
				res => {
					if(res.data.error){				
						general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: res.data.error})
					}
					else{
						commit('editQuestionTableColumn', payload);
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},	

	// Remove column in table question
	removeQuestionTableColumn: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.post('?module=evalvation_questionaire&action=delete_column&column_id=' + payload.column_id)
			.then(
				res => {
					if(res.data.error){				
						general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: res.data.error})
					}
					else{
						commit('removeQuestionTableColumn', payload);
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},	

	// Add department or program question table row
	addQuestionTableRow: ({commit}, payload) => {
		//general_mutations.showModalWorking(general_state.state);
		let value = payload.value ? payload.value : '';
		let clear_table = payload.clear_table ? payload.clear_table : false;
		let programs = router.currentRoute.params.programs ? router.currentRoute.params.programs.toString().split(",") : "";

		let call = (payload.questionnaire_type == 1 || payload.questionnaire_type == 4) ? 
			'?module=evalvation_data&action=insert_program_row&question_id='+payload.question_id+'&program_id=' + programs[0] : 
			'?module=evalvation_data&action=insert_oddelek_row&question_id='+payload.question_id+'&oddelek_id=' + payload.dep_or_prog_id
		axios.post(call, {additional_programs: payload.additional_programs, value:value, clear_table:clear_table})
			.then(
				res => {
					if(res.data.error){		
						//general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: res.data.error})
					}
					else{
						payload.row_id = res.data.row_id;
						commit('addQuestionTableRow', payload);
						//general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});			
	},	

	// Remove department table row
	deleteQuestionTableRow: ({commit}, payload) => {
		//general_mutations.showModalWorking(general_state.state);
		let call = (payload.questionnaire_type == 1 || payload.questionnaire_type == 4) ? 
			'?module=evalvation_data&action=delete_program_row&row_id='+payload.row_id : 
			'?module=evalvation_data&action=delete_oddelek_row&row_id='+payload.row_id;
		axios.post(call, {additional_programs: payload.additional_programs})
			.then(
				res => {
					if(res.data.error){		
						//general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: res.data.error})
					}
					else{
						commit('removeQuestionTableRow', payload);
						//general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});			
	},	

	// Update table cell
	saveQuestionTableCell: ({commit}, payload) => {
		//general_mutations.showModalWorking(general_state.state);

		let call = (payload.questionnaire_type == 1 || payload.questionnaire_type == 4) ? 
			'?module=evalvation_data&action=save_program_cell_data&row_id='+payload.row_id+'&column_id='+payload.column_id : 
			'?module=evalvation_data&action=save_oddelek_cell_data&row_id='+payload.row_id+'&column_id='+payload.column_id;
		axios.post(call, payload)
			.then(
				res => {
					if(res.data.error){				
						//general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: res.data.error})
					}
					else{
						//commit('saveTableCell', payload);
						//general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});			
	},

	// Edit column in fixed table
	editQuestionFixTableColumn: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.post('?module=evalvation_table&action=edit_table_column_title&questionaire_id=' + state.evalvation_questionnaire_detail.id + 
			'&table_number=' + payload.table_number + '&column_number=' + payload.column_number, payload)
			.then(
				res => {
					if(res.data.error){				
						general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: res.data.error})
					}
					else{
						commit('editQuestionFixTableColumn', payload);
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},	

    // Get list of image types for questionnaire
	getQuestionImageTypes: ({commit}) => {
		axios.get('?module=evalvation_questionaire&action=get_question_image_types')
			.then(
				res => {
					if(res.data.error){				
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: res.data.error})
					}
					else{
						commit('getQuestionImageTypes', res.data.image_types);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});	
	},
	
};


export default{
	state,
	getters,
	actions,
	mutations	
}